<template>
  <div class="actions">
    <a
      href="javascript:void(0)"
      class="btn btn-primary"
      @click.prevent="setSelected"
      data-toggle="modal"
      data-target="#update-famille-medicament"
    ><i class="icofont icofont-pencil" /></a>
    <a
      href="javascript:void(0)"
      class="btn btn-third"
      @click.prevent="deleting"
    ><i class="icofont icofont-close" /></a>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import { DELETE_FAMILLE_MEDICAMENT } from '../../../../graphql/sanitaire';
export default {
    props: {object: {type: Object, required: true}},
    data(){
        return{

        }
    },
    methods: {
        ...mapMutations({
            setSelectedRace: 'SET_SELECTED_OBJECT',
        }),
        setSelected(){
            this.setSelectedRace(this.object)
        },
        deleting(){
            this.$apollo.mutate({
                mutation: DELETE_FAMILLE_MEDICAMENT,
                variables: {
                    "uid": this.object.uid
                },
                update: (data) => {
                    if(data) console.log(`Type analyse ${this.object.libelle} at ${this.object.uid} deleted successfully`)
                    else console.log(`Error in deletting type analyse ${this.object.libelle}`)
                }
              }).then(() => {
                
              })
        }
    },
    computed: {
        ...mapGetters({

        })
    }
}
</script>

<style>

</style>